import Contactos from "../../components/contactos/contactos.component";

function ContactosPage() {
  
  return (
    <div>
        <Contactos/>
    </div>
  );
}

export default ContactosPage;
