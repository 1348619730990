import Galeria from "../../components/galeria/galeria.component";

function GaleriaPage() {
  
  return (
    <div>
        <Galeria/>
    </div>
  );
}

export default GaleriaPage;
