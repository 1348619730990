import Sustentabilidade from "../../components/sustentabilidade/sustentabilidade.component";

function SustentabilidadePage() {
  
  return (
    <div>
        <Sustentabilidade/>
    </div>
  );
}

export default SustentabilidadePage;
