import CulturaVM from "../../components/lazer/cultura-vilaMaior.component";
import LocaisVisitar from "../../components/lazer/locais-visitar.component";
//import OndeComer from "../../components/lazer/onde-comer.component";

function LazerExperienciasPage() {
  
  return (
    <div>
        <LocaisVisitar/>
        {/*<OndeComer/>*/}
        <CulturaVM/>
    </div>
  );
}

export default LazerExperienciasPage;
