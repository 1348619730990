import Card from "./carouselCard.component.jsx";

export const slidesEventosHome = [
  {
    content: (
      <Card imagen="https://i.ibb.co/NrLB0P2/le-2.jpg" legenda="Casamento" />
    )
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/6wrnD85/le-1.jpg" legenda="Casamento" />
    )
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/bvtnFzj/le-3.jpg" legenda="Casamento" />
    )
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/Wcfr5vD/le-4.jpg" legenda="Casamento" />
    )    
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/2KNMrWC/le-9.jpg" legenda="Casamento" />
    )
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/93Bcwhm/le-10.jpg" legenda="Casamento" />
    )  
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/xhCxTQw/le-5.jpg" legenda="7ª Edição - (Re)Descobrir" />
    ) 
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/f06ZSHw/le-6.jpg" legenda="7ª Edição - (Re)Descobrir" />
    ) 
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/7tqfTZN/le-7.jpg" legenda="7ª Edição - (Re)Descobrir" />
    ) 
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/4SjPFyK/le-8.jpg" legenda="7ª Edição - (Re)Descobrir" />
    )
  },
  {
    content: (
      <Card imagen="https://i.ibb.co/4sYHVdf/le-11.jpg" legenda="7ª Edição - (Re)Descobrir" />
    )
  },
]